
<template>
    <div class="container">
        <div class="title flex_center_between_box">
            <div class="title_txt flex_center">
                <img src="../../../assets/img/company/Own.png" />
                Investment By Subject
            </div>
            <div class="title_txt-right">
                <div class="tbl_search_box">
                    <div class="tbl_search">
                        <el-input v-model="searchTblName" type="text" clearable placeholder="Please enter name" @change="searchPersonByName()" />
                        <img src="../../../assets/img/detail/search_ic1.png" @click="searchPersonByName" />
                    </div>
                </div>
                <download-excel class="export-excel-wrapper computer_right_cont_box" :data="DetailsForm" :fields="json_fields" :header="title" name="Investment By Subject.xls">
                    <div class="btn_export flex_center" @click="exportCompany">
                        <img src="../../../assets/img/search/export_ic1.png" />
                        Export
                    </div>
                </download-excel>
            </div>
        </div>

        <div class="table">
            <div class="right_cont_box">
                <div class="table_box">
                    <el-table ref="treeTable" class="computer_right_cont_box" :data="parentTablelist" border style="width: 100%">
                        <!-- 空数据状态的插槽 -->
                        <template slot="empty">
                            <noDate />
                        </template>

                        <el-table-column label="Company Name" align="left" show-overflow-tooltip width="410" sortable :sort-method="sortName" :resizable="false" class-name="company_link">
                            <template slot-scope="scope">
                                <div @click="setid1(scope.row)" exact class="tbl_company" v-show="showTranslate">{{ scope.row.nameCn }}</div>
                                <div @click="setid1(scope.row)" exact class="tbl_company" v-show="!showTranslate">{{ scope.row.name }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Type" align="left" prop="type" width="258" show-overflow-tooltip :resizable="false"></el-table-column>
                        <el-table-column label="Country" prop="country" width="200" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                            <template slot-scope="scope">
                                <span @click="gotocountry1(scope.row)">{{ scope.row.country }}</span>
                            </template>
                            scope.row.country
                        </el-table-column>
                        <el-table-column label="Shares(%)" prop="shares" width="140" align="left" sortable :sort-method="sortScore" show-overflow-tooltip :resizable="false" />
                        <el-table-column label="Relations" prop="relationshipEn" width="150" align="right" show-overflow-tooltip :resizable="false" />
                    </el-table>
                        <!-- 移动端 -->
                 <div class=" phone_page_bg">
                  <div class="table_box">
                    <ul class="phone_table_box">
                        <li class="phone_table_box_item" v-for="(item,index) in parentTablelist" :key="index">
                         <div class="phone_table_box_item_right ">
                           <h1 class="line_1" @click="setid1(item)"  > {{item.name}}</h1>
                           <h2 class="line_1" > Type: {{item.type}}</h2>
                           <h2 class="line_1"><span >Country:{{item.country}}</span></h2>
                           <h2 class="line_1"><span >Relations:{{item.relationshipEn}}</span> </h2>
                           <h2 class="line_1"><span >Shares(%):{{item.shares}}</span> </h2>
                         </div>
                      </li>
                    </ul>
                  </div>
                </div>
                    <div class="page_i_box">
                        <Page :totalFont="true" :total="page.total" @onPageChange="onPageChangeList" @currtentPageChange="currtentPageChange"></Page>
                        <div class="search_rsult_txt">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
                    </div>
                </div>
           
            </div>
        </div>

        <div class="footer computer_right_cont_box">
            <div class="ecahrts-header">
                <span class="title-left">
                    <img src="../../../assets/img/company/echarts.png" alt="" style="margin-right: 8px" />
                    Charts
                </span>

                <div class="head_loging" :class="loginStatus">
                    <el-switch v-model="loginStatus" @change="loginPop" active-color="#13ce66"></el-switch>
                </div>
            </div>
            <div v-show="loginStatus" class="ecahrtstu">
                <div ref="trendChart" class="trendEchartBox1" />
                <div class="echartsBtns">
                    <el-button icon="el-icon-plus" :disabled="level >= 3" @click="changeLevel(level + 1)" />
                    <el-button icon="el-icon-minus" :disabled="level <= 1" @click="changeLevel(level - 1)" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getInvestment, getCompanyChart } from '../../../api/companyApi';
import crypto from '@/utils/crypto';
import noDate from '@/components/tableNoDate';
import Page from '@/components/page';
// import { data } from './data'
import { mixins1 } from '@/mixins/index';
import { formatterTxt, formatterTxtTips, formatList } from './utils';
export default {
    mixins: [mixins1],
    components: { noDate, Page },
    data() {
        return {
            loginStatus: false, //登录状态,
            level: 1,

            parentTablelist: [],
            companyAaaid: '156021447566',
            comIcon1: 'image://' + require('@/assets/img/echarts/comIcon1.png'),
            comIcon2: 'image://' + require('@/assets/img/echarts/comIcon2.png'),
            comIcon3: 'image://' + require('@/assets/img/echarts/comIcon3.png'),
            comIcon4: 'image://' + require('@/assets/img/echarts/comIcon4.png'),
            comIcon5: 'image://' + require('@/assets/img/echarts/comIcon5.png'),
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            icon: '',
            parentTable1: [],
            // 下载
            title: 'Invuestment',
            json_fields: {
                'Company Name': 'name',
                Type: 'type',
                Country: 'country',
                'Shares(%)': 'shares',
                Relations: 'relationshipEn',
            },
            DetailsForm: [],
            searchTblName: '',
            tableList: [],
        };
    },
    computed: {
        //index传值 是否翻译
        showTranslate: {
            get() {
                return this.$parent.indexIsTranslate;
            },
            set() {},
        },
        curPagePower: {
            get() {
                return JSON.parse(this.$parent.powerToPage);
            },
            set() {},
        },
    },
    watch: {},
    mounted() {
        this.getlist();
        this.echartslist();
        this.$echarts.init(this.$refs.trendChart).on('click', this.handleChartClick)
    },

    methods: {
        handleChartClick(params) {
            if (!this.curPagePower[1].power) {
                this.$parent.getOrdersAdd(19).then(res=>{
                   if(!res){
                  
                    this.level=1
      
                    this.$nextTick(() => {
                this.echarts();
            });
                   }
                })
    } 
},
        // 人名筛选
        searchPersonByName() {
            let list = this.tableList;
            let keyWord = this.searchTblName;
            var arr = [];
            if (keyWord == '') {
                this.parentTablelist = list;
                this.parentTable1 = list;
            } else {
                arr = list.filter(item => {
                    return item.name.toUpperCase().indexOf(keyWord.toUpperCase()) !== -1;
                });
                this.parentTable1 = arr;
            }
            this.pageList();
        },
        sortName(a, b) {
            return a.name.localeCompare(b.name);
        },
        // 排序
        sortScore(a, b) {
            return a.shares - b.shares;
        },
        // 分页
        pageList() {

            this.parentTablelist = this.parentTable1.filter((item, index) => index < this.page.pageNo * this.page.pageSize && index >= this.page.pageSize * (this.page.pageNo - 1));
            this.page.total = this.parentTable1.length;
        },
        onPageChangeList(pageNo) {
            // this.pageNo += 1
            this.page.pageNo = pageNo;
            this.pageList();
        },
        currtentPageChange(pageSize) {
            // this.pageNo += 1
            this.page.pageSize = pageSize;
            // this.init()
            this.pageList();
        },
        // 国家跳转
        gotocountry1(row) {
            if (row.countryCode != 'n.a.') {
                let routeData = this.$router.resolve({
                    path: '/country?' + '&companyCountry=' + row.countryCode,
                });

                window.open(routeData.href, '_blank');
            }
            //  if(!row.countryCode.includes(';')){

            //  }
        },
        //点击公司名跳转详情
        setid1(row) {
            console.log('inves--', row);
            // this.$emit('updateSubLink', 0);
            row.company_name_en = row.name;
            sessionStorage.setItem('company', JSON.stringify(row));
            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + row.aaaId + '&companyCountry=' + row.countryCode,
            });
            window.open(routeData.href, '_blank');
        },
        // 获取表格数据
        getlist() {
            // console.log(1111)
            const params = {
                id3a: this.$route.query.id3a,
                companyCountry: this.$route.query.companyCountry,
            };
            getInvestment(params).then(res => {
                let date = JSON.parse(crypto.decrypt(res));
                this.tableList = date.data;
                this.parentTable1 = date.data;
                this.pageList();
            });
        },

        //导出
        exportCompany() {
            console.log('ownership- curPagePower--', this.curPagePower);
            if (this.curPagePower[0].power) {
                this.DetailsForm = this.parentTable1;
            } else {
                this.message()
                // this.$parent.getOrdersAdd(1).then(res => {
                //     if (res) this.DetailsForm = this.parentTable1;
                // });
            }
        },
        // 获取echarts数据
        async echartslist() {
            const params = {
                id3a: this.$route.query.id3a,
                // id3a: JSON.parse(sessionStorage.getItem('company')).aaa_id,
                // companyCountry: JSON.parse(sessionStorage.getItem('company')).company_country,
                companyCountry: this.$route.query.companyCountry,
                charType: 2,
            };
            const res1 = await getCompanyChart(params);
            // console.log(res1);
            let ecahrtsdata = JSON.parse(crypto.decrypt(res1));
            this.echartsdate = ecahrtsdata.data;

            // data=this.ecahrtsdata
            this.$nextTick(() => {
                this.echarts();
            });
            console.log(this.echartsdate);
        },
        changeLevel(newLel) {
            if (!this.curPagePower[1].power) {
                this.$parent.getOrdersAdd(19)
    } 
            this.level = newLel;
            this.$nextTick(() => {
                this.echarts();
            });
            // this.treeChart(this.echartData, newLel)
        },
        // chaartd配置
        echarts() {
            const that = this;
            let leval = that.level;
            const initialTreeDepth = leval;

            that.echartsdate = JSON.parse(JSON.stringify(that.echartsdate).replace(/subsetList/g, 'children'));
            console.log(that.echartsdate);
            //  that.echartsdate.map((item,index)=>{

            //  })
            let data = that.echartsdate;
            const myChart = this.$echarts.init(this.$refs.trendChart);
            myChart.showLoading();

            myChart.hideLoading();

            const symbol = data => {
                if (`${data.invtypecode}` === '3') {
                    // 外国公司
                    return this.comIcon3;
                }
                if (`${data.invtypecode}` === '5') {
                    // 国营企业
                    return this.comIcon5;
                }
                if (`${data.code}` === this.$route.query.id3a) {
                    // 主体
                    console.log(this.comIcon4);
                    return this.comIcon4;
                }
                if (data.type == '2') {
                    // 个人
                    return this.comIcon2;
                }
                return this.comIcon1;
            };
            const option = {
                tooltip: {
                    trigger: 'item',
                    triggerOn: 'mousemove',
                    fontSize: 12,
                    formatter: params => formatterTxtTips(params),
                },
                series: [
                    {
                        type: 'tree',
                        data: [data],
                        top: '18%',
                        bottom: '14%',
                        // roam: true,
                        layout: 'radial',
                        symbol: (value, param) => symbol(param.data),
                        symbolSize: 30,
                        initialTreeDepth,
                        animationDurationUpdate: 750,
                        emphasis: {
                            focus: 'ancestor',
                        },
                        orient: 'TB',
                        label: {
                            fontSize: 9,
                            formatter: params => formatterTxt(params),
                            rotate: 360,
                        },
                        leaves: {
                            label: {
                                fontSize: 9,
                                formatter: params => formatterTxt(params),
                                rotate: 360,
                            },
                        },
                    },
                ],
            };
            myChart.setOption(option);

            // myChart.resize()
        },

        handleChange(val) {
            this.icon = val;
            // this.iconlist=val
            // this.iconlist.some(item=>{
            //     console.log(item);
            // })
        },
        loginPop() {
            this.echartStatus = true;
                this.echarts();
 
            // if (this.curPagePower[1].power) {
            //     this.echartStatus = true;
            //     this.echarts();
            // } else {
            //     this.$parent.getOrdersAdd(13).then(res => {
            //         if (res) {
            //             this.echarts();
            //         }
            //     });
            // }
        },
    },
};
</script>
<style scoped lang="less">
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}
.echartsBtns {
    position: absolute;
    top: 30px;
    left: 30px;
}
.echartsBtns ::v-deep button {
    padding: 4px 15px !important;
    font-size: 15px;
    background-color: transparent;
}
.Company Snapshot {
}

.Country Data {
}

.title,
.ecahrts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 69px;

    .title-left {
        display: flex;
        font-size: 16px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #022955;
    }
}

.footer {
    margin-top: 20px;
    padding: 20px;

    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}

.ecahrts-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-left {
        display: flex;
    }
}

.table {
    margin-top: 20px;
}

.trendEchartBox1 {
    width: 980px;
    height: 624px;
}

.ecahrtstu {
    display: flex;
    position: relative;
    justify-content: center;
    width: 980px;

    background: #ffffff;
}

.table_box /deep/ .el-table th > .cell:first-child {
    padding-left: 20px;
}
.table_box /deep/ .el-table .cell,
.el-table--border td:first-child .cell,
.table_box /deep/ .el-table--border th:first-child .cell {
    padding-left: 19px;
}
.table_box /deep/ .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #1290c9;
}
.table_box /deep/ .el-table .sort-caret.descending {
    border-top-color: #c0c4cc;
}
.table_box /deep/ .el-table .sort-caret.ascending {
    border-bottom-color: #c0c4cc;
}
.table_box /deep/ .el-table .descending .sort-caret.descending {
    border-top-color: #1290c9;
}

.tbl_search_box {
    /* position: absolute;
  left: 0; */
    margin-right: 10px;
}
.title_txt-right {
    display: flex;
}

.tbl_search {
    position: relative;
    width: 290px;
    height: 40px;
    z-index: 9;
}
.tbl_search /deep/ .el-input .el-input__inner {
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(46, 118, 167, 0.4);
    border-radius: 8px;
    border: 1px solid #1290c9;
    padding: 0 40px 0 20px;
}
.tbl_search .el-input {
    height: 100%;
}
.tbl_search /deep/ .el-input .el-input__suffix {
    right: 40px;
}
.tbl_search img {
    position: absolute;
    width: 18px;
    right: 20px;
    top: 12px;
}
.title {
    margin-bottom: 10px;
}
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}
.title_txt img {
    width: 20px;
    margin-right: 8px;
}
@media (max-width: 821px) {
        .phone_table_box_item{
     display: flex;
     /* justify-content: center; */
     /* align-items: center; */
     padding: 20px 0;
     border-bottom:1px solid #e0dce5;
    }
   .table_box .phone_table_box_item img {
 
     width: 28px;
     height: 17px;
     flex: none;
     margin-right: 4px;
     box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
    }
    .phone_table_box_item_right{
      font-size: 14px;
      margin-left: 20px;
         width: 85%;
         
    }
    .phone_table_box_item_right h1{
        font-size: 14px;
         font-weight: normal;
             
   
    }
     .phone_table_box_item_right h2{
        font-size: 12px;
        color: #656373;
         font-weight: normal;
         line-height: 22px;
       
       
    }
    .title{
        margin-top: 20px !important;
    }
     .tbl_search{
        width: 200px !important;
    }
        .phone_table_box_item_right .line_1 {
    white-space: nowrap !important;
}
    }
</style>